import Link from "next/link";
import styles from "./styles/splitTwoImage.module.css";

function showImage(linkImage, link, img) {
  if (linkImage && link) {
    return (
      <Link href={link.href} legacyBehavior>
        <a className={styles.link}> {img} </a>
      </Link>
    );
  }
  return img;
}

export function SplitTwoImage({ imgLeft, imgRight, title, subTitle, linkLeft, linkRight }) {
  let link = undefined;
  let leftLinkStyles = ` ${styles.link} ${styles.linkLeft}`;
  let rightLinkStyles = ` ${styles.link} ${styles.linkRight}`;
  if (linkLeft === undefined) {
    if (linkRight !== undefined) {
      link = linkRight.href;
      leftLinkStyles = `
      ${styles.link}
      ${styles.linkLeft}
      ${styles.fullLeft}`;
      rightLinkStyles = `
      ${styles.link}
      ${styles.linkRight}
      ${styles.fullRight}`;
    }
  } else {
    if (linkRight === undefined) {
      link = linkLeft.href;
      leftLinkStyles = `
      ${styles.link}
      ${styles.linkLeft}
      ${styles.fullLeft}`;
      rightLinkStyles = `
      ${styles.link}
      ${styles.linkRight}
      ${styles.fullRight}`;
    }
  }

  return (
    <article className={styles.container}>
      {link && (
        <Link href={link} legacyBehavior>
          <a className={styles.link} />
        </Link>
      )}
      {/* Background */}
      <div className={styles.background}>
        <div className={styles.left}>{showImage(link === undefined, linkLeft, imgLeft)}</div>
        <div className={styles.right}>{showImage(link === undefined, linkRight, imgRight)}</div>
      </div>
      {/* Foreground */}
      <div className={styles.foreground}>
        <div className={styles.titleContainer}>
          <h1 className={styles.title}>{title}</h1>
          <p className={styles.subtitle}>{subTitle}</p>
        </div>

        {linkLeft && (
          <div className={leftLinkStyles}>
            <a href={linkLeft.href}>{linkLeft.text}</a>
          </div>
        )}
        {linkRight && (
          <div className={rightLinkStyles}>
            <a href={linkRight.href}>{linkRight.text}</a>
          </div>
        )}
      </div>
    </article>
  );
}
