import PropTypes from "prop-types";
import Link from "next/link";
import { getSanityNextImage } from "@lib/getImages";
import styles from "./styles/card.module.css";
import title from "title";

export function Card({ editorial }) {
  if (!editorial) {
    return null;
  }
  if (!editorial.card) {
    return null;
  }
  const { cardImage, heading, subheading } = editorial.card;

  return (
    <Link href={`/editorials/${editorial.slug.current}`} legacyBehavior>
      <a>
        <article className={styles.card}>
          <div className={styles.image}>
            {getSanityNextImage(
              cardImage,
              { small: "600", medium: "600", large: "800" },
              cardImage.altText
            )}
          </div>
          <div className={styles.footer}>
            <h3>{title(heading)}</h3>
            <p>{subheading}</p>
            <small>{cardImage.caption}</small>
          </div>
        </article>
      </a>
    </Link>
  );
}

Card.propTypes = {
  editorial: PropTypes.shape({
    card: PropTypes.shape({
      cardImage: PropTypes.shape({
        altText: PropTypes.string.isRequired,
        caption: PropTypes.string.isRequired,
        asset: PropTypes.object.isRequired,
      }),
      heading: PropTypes.string.isRequired,
      subheading: PropTypes.string.isRequired,
    }).isRequired,
    slug: PropTypes.shape({
      current: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};
