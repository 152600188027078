import PropTypes from "prop-types";
import Link from "next/link";
import { A } from "@components/Anchor";
import styles from "./styles/stores.module.css";

function Stores(props) {
  const { store } = props;

  return (
    <div className={styles.wrapper}>
      <h4 className={styles.title}>
        <Link href="/stores" passHref prefetch={false} legacyBehavior>
          <A>Our Stores</A>
        </Link>
      </h4>
      <ul className={styles.list}>
        {store?.map((st) => (
          <li className={styles.listItem} key={st.key}>
            <Link href={`/stores/${st.slug.current}`} passHref prefetch={false} legacyBehavior>
              <A>{st.city}</A>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
}

Stores.propTypes = {
  store: PropTypes.arrayOf(PropTypes.object),
};

export default Stores;
