import PropTypes from "prop-types";
import { Grid, Item, Padding, Height } from "./Spread/index";
import { SimpleBlockContent } from "../SimpleBlockContent";
import { getSanityNextImage } from "@lib/getImages";
import { getLink } from "@lib/getLink";
import Link from "next/link";
import VideoPlayer from "@components/VideoPlayer";

function getSpreadItemContent(content, cta, poster, priority) {
  const link = cta ? getLink(cta) : null;

  if (content._type === "richImage") {
    const picture = getSanityNextImage(
      content.asset,
      { small: "800", medium: "1200", large: "2400" },
      content.altText,
      "cover",
      priority
    );
    return link && link.href ? (
      <Link href={link.href} legacyBehavior>
        <a style={{ display: "flex", width: "100%" }}>{picture}</a>
      </Link>
    ) : (
      <div style={{ display: "flex", width: "100%" }}>{picture}</div>
    );
  }
  if (content._type === "spreadItemVideo") {
    let posterSrc = null;
    if (poster && poster.asset) {
      posterSrc = getSanityNextImage(
        poster.asset,
        { small: "800", medium: "800", large: "800" },
        "Video poster image"
      );
    }
    return (
      <VideoPlayer
        loop
        autoPlay
        muted
        controls
        src={content.href || content.videoSrc.asset.url}
        poster={posterSrc}
        style={{ width: "100%" }}
      />
    );
  }
  return <SimpleBlockContent blocks={content?.text?.text} />;
}

function SpreadSection(props) {
  const {
    spreadItems,
    itemsCountSmall,
    itemsCountMedium,
    itemsCountLarge,
    fullBleed,
    height = "AUTO",
    priority = false,
  } = props;

  const hasTextOrVideo = spreadItems.findIndex((item) => item._type !== "spreadItemImage") !== -1;

  return (
    <Grid full={fullBleed} height={Height[height]}>
      {spreadItems.map((spreadItem) => {
        const content = spreadItem.content || spreadItem;
        const { cta } = spreadItem;
        const padding = spreadItem.padding ? spreadItem.padding : "NONE";
        const poster = spreadItem.poster || null;

        return (
          <Item
            key={spreadItem._key}
            itemsCountSmall={itemsCountSmall}
            itemsCountMedium={itemsCountMedium}
            itemsCountLarge={itemsCountLarge}
            type={content._type}
            full={spreadItem.full}
            hasTextOrVideo={hasTextOrVideo}
            padding={Padding[padding]}
            background={spreadItem.background}
            hide={spreadItem.hide}
          >
            {getSpreadItemContent(content, cta, poster, priority)}
          </Item>
        );
      })}
    </Grid>
  );
}

SpreadSection.propTypes = {
  spreadItems: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.object,
      cta: PropTypes.object,
      padding: PropTypes.string,
    })
  ),
};

export default SpreadSection;
