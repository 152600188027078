import PropTypes from "prop-types";
import Link from "next/link";
import styles from "./styles/summer.module.css";

export function SummerSale({ children, title, subTitle, link }) {
  return (
    <article className={styles.container}>
      <Link href={link.href} legacyBehavior>
        <a className={styles.link} />
      </Link>
      <div className={styles.background}>
        <div className={styles.imgWrapper}>{children}</div>
      </div>
      {/* Foreground */}
      <div className={styles.foreground}>
        <h1 className={styles.title}>{title}</h1>
        <div className={styles.subheading}>
          <p className={styles.subtitle}>{subTitle}</p>
          <Link href={link.href} legacyBehavior>
            <a className={styles.link}>{link.text}</a>
          </Link>
        </div>
      </div>
    </article>
  );
}

SummerSale.propTypes = {};
